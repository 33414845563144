define("discourse/plugins/pg-heading-user-badges/discourse/initializers/pg-heading-user-badges", ["exports", "discourse/lib/plugin-api", "discourse/widgets/raw-html", "virtual-dom"], function (_exports, _pluginApi, _rawHtml, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { iconNode } from "discourse-common/lib/icon-library";

  function attachPostAds(api, siteSettings) {
    api.decorateWidget("post-avatar:after", dec => {
      const attrs = dec.attrs;
      // var _badge_icon = iconNode("fa-user");
      let _badgesInfo = [];
      const _pgy_badges = attrs.pgy_badges ? attrs.pgy_badges : [];
      if (attrs.pgy_badges.length > 0) {
        for (var i = 0; i < _pgy_badges.length; i++) {
          var _name = _pgy_badges[i].name;
          var _icon = _pgy_badges[i].icon;
          _badgesInfo.push(`<span class="user-card-badge-link">
        <span data-badge-name="${_name}" class="user-badge badge-type-institution ember-view">
          <svg class="fa d-icon d-icon-${_icon} svg-icon svg-string" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#${_icon}"></use></svg>
          <span class="badge-display-name">${_name}</span>
        </span></span>`);
        }
      }
      var pgy_user_badges = `<a href="${attrs.usernameUrl}" classNames="trigger-user-card nt-avatar" data-user-card="${attrs.username}">${_badgesInfo.join(' ')}</a>`;
      return [(0, _virtualDom.h)("div.badge-section.pgy-heading-user-badges", {
        attributes: {
          "style": 'display: none;'
        }
      }, new _rawHtml.default({
        html: pgy_user_badges
      }))];
    });
  }
  var _default = _exports.default = {
    name: "pg-heading-user-badges",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.pg_heading_user_badges_enabled) {
        (0, _pluginApi.withPluginApi)("0.1", api => {
          attachPostAds(api, siteSettings);
          api.includePostAttributes("pgy_badges");
        });
      }
    }
  };
});